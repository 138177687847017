<template>
  <div>
    <div id="content">
      <Splash
        :bannerImage="page[0].acf.banner_image"
      />
        <div class="ourContainer">
          <template>
            <Tileshow />
          </template>
          <div class="wp">
            <b-row>
              <b-col
                :class="{ 'col-lg-9 col-md-9' :page[0].acf.right_sidebar === true }"
              >
                <b-row>
                  <b-col>
                    <span class="wpContent" v-html="page[0].content.rendered"></span
                  ></b-col>
                </b-row>
              </b-col>

              <!-- Right Side Bar -->
            <template v-if="page[0].acf.right_sidebar === true">
              <b-col>
                <!-- Ads -->
                <template v-if="page[0].acf.ad_links.length">
                  <SideBarBlock
                    v-for="(link, index) in page[0].acf.ad_links"
                    :key="index"
                    type="ad"
                    :image="link.image"
                    :url="link.url"
                  />
                </template>
                <!-- End Ads -->

                <!-- Downloads -->
                <template v-if="$route.path === '/our-sports/swimming/records'">
                  <template v-if="page[1].acf.downloads.length">
                    <div class="downloadsLink">
                      <h4 class="sideTitle">
                        <template v-if="page[1].acf.downloads_header_text == true ">
                          <h4 class="sideTitle downTop">
                            {{page[1].acf.downloads_header_text}}
                            <hr class="hrLine">
                          </h4>
                        </template>
                        <template v-else>
                          <h4 class="sideTitle downTop">
                            Downloads
                            <hr class="hrLine">
                          </h4>
                        </template>
                      </h4>
                      <SideBarBlock
                        v-for="(link, index) in page[1].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/para-swimming/records'">
                  <template v-if="page[3].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[3].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[3].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[3].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/masters/records'">
                  <template v-if="page[2].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[2].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[2].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[2].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="page[0].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[0].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[0].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop"> Downloads 
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[0].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>                  

                <!--End Downloads -->

                <!-- URL Links -->
                <template v-if="page[0].acf.useful_links.length">
                  <div class="usefulLinks">
                    <template v-if="page[0].acf.usefull_links_text == true">
                      <h4 class="sideTitle top">
                        {{page[0].acf.usefull_links_text}}
                        <hr class="hrLine">
                      </h4>
                    </template>
                    <template v-else>
                      <h4 class="sideTitle top">
                        Useful Links
                        <hr class="hrLine">
                      </h4>
                    </template>
                    <SideBarBlock
                      v-for="(link, index) in page[0].acf.useful_links"
                      :key="index"
                      type="link"
                      :title="link.title"
                      :url="link.url"
                    />
                  </div>
                </template>                  

                <!-- End URL Links -->
              </b-col>
            </template>
              <!-- End Right Side Bar -->
            </b-row>

            <!-- Find A Club Banner -->
            <!-- <template v-if="page[0].acf.club_finder_banner === true">
              <ClubFinder :page="page[0]" />
            </template> -->
            <!-- End Find A Club Banner -->

            <!-- Health Banner -->
            <template v-if="page[0].acf.health_section === true">
              <Benefits :page="page[0]" />
            </template>
            <!-- End Health Banner -->

            <!-- Up Coming Events -->
            <!-- <Upcoming :discipline="itemLink(page[0].slug)" total="two" /> -->
            <!-- End Up Coming Events -->
          </div>
        </div>      

      <!-- <div class="pad">
        <News :cat="$route.name" :newsPage="newsPage" />
      </div> -->
      <!-- Sign Up Banner -->
      <!-- <template v-if="page[0].acf.news_letter_sign_up === true">
        <div>
          <SignUp />
        </div>
      </template> -->
      <!-- End Sign Up Banner -->
    </div>    
    <Footer />
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import SideBarBlock from "@/components/sidebarBlock.vue";
// import ClubFinder from "@/components/clubFind.vue";
import Benefits from "@/components/benefits.vue";
// import Upcoming from "@/components/upComing.vue";
import Splash from "@/components/splash.vue";
// import News from "@/components/news.vue";
import Tileshow from "@/components/tileshow.vue";
import Footer from '@/components/footer.vue';
// import SignUp from "@/components/signUp.vue";
export default {
  name: "main",
  components: {
    SideBarBlock,
    Benefits,
    // Upcoming,
    // ClubFinder,
    Splash,
    // News,
    Tileshow,
    Footer
    // SignUp,
  },
  data() {
    return {
      page: [],
      title: '',
      description: '',
      parentpage:[],
      newsPage: false,
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    route: function() {
      var r = this.$route.fullPath.split("/");
      if (r[0] === "") {
        r[0] = "home";
      }
      return r;
    },
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    routeTitle(item) {
      var low = item;
      var rep = low.replace(/-/g, " ");
      return rep;
    },
  },

  mounted() {
    apiCall
      .wpInfo("wp/v2/pages?slug=" + this.itemLink(this.$route.name))
      .then(({ data }) => {
        this.page = data;
        this.title = data[0].title.rendered;
        this.description = data[0].yoast_head_json.og_description;
        this.loading = false;
        this.parentpage = data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
li.nav-item.linkText {
  line-height: 18px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h4.sideTitle.downTop {
  margin-top: -10px;
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 10px;
}
.usefulLinks {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 10px;
  border-radius: 5px;
  padding-right: 10px;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 20px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
::v-deep .wpContent > h1 > strong{
  font-weight: 500;
}
::v-deep .wpContent > h1 {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
  padding-top: 20px;
}
::v-deep .wpContent > h2 {
  padding: 30px 0;
  color: #201546;
  font-size: 24px;
  font-weight: 500;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > p {
  color: #201546;
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
.hide0 {
  display: none;
}
.nav-item > a {
  color: #009fe3;
}
.pad {
  padding-top: 35px;
  margin-top: 35px;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #707070;
  width: 7%;
}
::v-deep .wpContent > ul > li > a {
  color: #009fe3;
}
::v-deep .wpContent > ul > li > a {
  color: #009fe3;
}
::v-deep tr {
  background-color: #f7f7f7;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
}
</style>
