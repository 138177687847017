<template>
  <div>
    <b-row v-if="$route.params.slug === 'masters'">
      <p class="recordpad" v-html="page[2].content.rendered"></p>
    </b-row>
    <b-row v-else-if="$route.params.slug === 'swimming'">
      <p class="recordpad" v-html="page[0].content.rendered"></p>
    </b-row>
    <!-- <b-row v-else-if="$route.params.slug === 'water-polo'">
      <p class="recordpad" v-html="page[4].content.rendered"></p>
    </b-row> -->
    <!-- <b-row v-else-if="$route.params.slug === 'open-water'">
      <p class="recordpad" v-html="page[3].content.rendered"></p>
    </b-row> -->
    <b-row v-else-if="$route.params.slug === 'para-swimming'">
      <p class="recordpad" v-html="page[1].content.rendered"></p>
    </b-row> 
    <!-- <b-row v-else-if="$route.params.slug === 'artistic-swimming'">
      <p class="recordpad" v-html="page[1].content.rendered"></p>
    </b-row> -->
    <!-- <b-row else>
      <p v-html="page[0].content.rendered"></p>
    </b-row> -->
    <!-- <button v-print>Print the entire page</button> -->
    <!-- <div id="printMe" style="background:red;">
        <p>Test</p>
        <p>Test</p>
    </div> -->
    <b-row>
      <b-col>
        <div id="printMe" class="PRINTBTNONE">
          <div v-for="(text, index) in acf" :key="index" v-show="$route.params.slug === text.acf.discipline_select" class="recordsDepth">
            <!-- <h2 class="titleBold" v-html="text.title.rendered"></h2> -->
            <div class="wp">
              <div class="accordion" role="tablist">
                <b-card
                  no-body
                  class="mb-1"
                  v-for="(test, index) in text.acf.table_list"
                  :key="index"
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle="'table' + test.id"
                      :id="index"
                      class="faqQ"
                    >
                      <span class="recordsTitle">
                        {{ test.title }}
                      </span>
                      <span class="arrow" >
                        <span class="plusIcon">
                          <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                        </span>
                      </span>
                    </b-button>
                  </b-card-header>
                  <b-collapse
                    :id="'table' + test.id"
                    :accordion="'table' + test.id"
                    role="tabpanel"
                  >
                    <button class="print PRINTBTN" v-print="'#printMe'">Print Table</button>
                    <b-card-body>
                      <b-card-text v-html="test.text"></b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <h2 class="morerecords">MORE RECORDS:</h2>
    <b-row>
      <b-col v-for="(rec, index) in page" :key="index" class="recCols test" cols="12" lg="3" md="3">
        <b-nav-item :href="rec.link.replace('/records/' , '/records')">
          <span class="swimming recordHover" v-if="rec.link.includes('/swimming/')">Swimming</span>
          <span class="masters recordHover" v-else-if="rec.link.includes('/masters/')">Masters</span>
          <span class="paraswimming recordHover" v-else-if="rec.link.includes('/para-swimming/')">Para Swimming</span>
          <!-- <span class="openwater recordHover" v-else-if="rec.link.includes('/open-water/')">Open Water</span> -->
        </b-nav-item>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "records",
  data() {
    return {
      acf: [],
      fame: [],
      table:[],
      page: [],
      pick: "",
      selectedType:"",
      options: [
        { value: "", text: "All" },
        { value: "swimming", text: "Swimming" },
        { value: "para-swimming", text: "Para Swimming" },
        { value: "diving", text: "Diving" },
        { value: "open-water", text: "Open Water" },
        { value: "water-polo", text: "Water Polo" },
        { value: "masters", text: "Masters" },
        { value: "artistic-swimming", text: "Artistic Swimming" },
      ],
    };
  },
  computed: {
    computed_items: function () {
      let filterType= this.selectedType
      return this.fame.filter(function(person){
        let filtered = true
        if(filterType && filterType.length > 0){
          filtered = person.acf.discipline_select == filterType
        }
        return filtered
      })
    }
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/records?per_page=50")
      .then(({ data }) => {
        this.fame = data;
        this.table = data;
        this.acf = data;
        console.log("this.acf")
        console.log(this.acf)
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  created() {
    apiCall
      .wpInfo("wp/v2/pages?slug=records")
      .then(({ data }) => {
        this.page = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep .recordpad > h1 {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
.recCols >li > a {
  padding-left: 0px;
  padding-right: 0px;
}
.recordHover:hover {
  background-color: #201546;
  color: #fff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  display: block;
}
.swimming { 
  background-color: #009fe3;
  color: #fff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 10pt;
}
.masters {
  background-color: #ff9901;
  color: #fff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 10pt;
}
.paraswimming {
  background-color: #afca0b;
  color: #fff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 10pt;
}
.openwater {
  background-color: #5c439c;
  color: #fff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 10pt;
}
h2.morerecords {
  font-size: 10pt;
  font-weight: 500;
  color: #1e1544;
  margin-top: 50px;
  margin-bottom: 20px;
}
.recordpad {
  padding-left: 15px;
}
.collapsed:after {
  content: 'Open';
  float: right;
  padding-right: 15px;
}
.not-collapsed:after {
  content: 'Close';
  float: right;
  padding-right: 15px;
}
span.recordsTitle {
  position: relative;
  top: 0px;
}
span.arrow {
  float: right;
  display: grid;
  text-align: center;
}
::v-deep  tr > td.topmast{
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  border-right: 0px;
  border-left: 0px;
}

::v-deep td.table-sub-title {
  background-color: #fff!important;
  font-weight: bold!important;
}
::v-deep td.table-sub-title >  p{
  margin-bottom: 0px;
}
/* ::v-deep th {
  background: #009fe3 0 0 no-repeat padding-box;
  color: #fff;
  padding: 5px 5px 5px 5px!important;
  font-size: 13px;  
}
::v-deep td {
  border: 4px solid #fff;
  padding: 5px 5px 5px 5px!important;
  font-size: 13px!important;
  color: #201546;
  width: 0px!important;
} */
.print {
  background: #1e1544;
  border-color: #1e1544;
  color: #fff;
  margin-left: 20px;
  margin-top: 15px;
}
.titleBold {
  font-weight: bold;
}
::v-deep .card-header {
  background-color: transparent;
}
::v-deep.btn-secondary {
  color: #1f1545;
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbg.jpg');
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
  border: none!important;
  background-repeat: no-repeat;
  background-size: cover;
}
::v-deep .btn-secondary:hover {
  color: #1f1545;
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbgHover.jpg');
  border: none!important;
  background-repeat: no-repeat;
  background-size: cover;
}
.recordsDepth {
  margin-bottom: 30px;
}
.acfImage {
  width: 100%;
}
li.nav-item.hof {
  overflow: hidden;
  position: inherit;
}
h2.textTitle {
  color: #029fe3;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 12px;
  position: absolute;
  bottom: -8px;
  text-align: center;
  left: 0;
  right: 0;
  background: #1f1545;
  overflow: hidden;
}
h2.textTitle:hover {
  color: #1f1545;
  background: #029fe3;
}
::v-deep .card-title {
  font-size: 20px !important;
  color: black;
}
.card {
  border: 0;
}
.hof>a{
  padding: 0; 
}
::v-deep .custom-select {
  height: 56px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 1420px) {
  h2.textTitle {
    font-size: 24px;
  }
}
@media only screen and (max-width: 500px) {
  .recordsDepth {
    margin-bottom: 0;
  }
  h2.textTitle {
    color: #029fe3;
    font-weight: bold;
    padding-bottom: 15px;
    padding-top: 12px;
    position: initial;
    bottom: -8px;
    text-align: center;
    left: 0;
    right: 0;
    background: #1f1545;
    overflow: hidden;
    font-size: 20px;
  }
  ::v-deep.btn-secondary {
    color: #1f1545;
    background-color: #eff8fc;
    border-color: #eff8fc;
    text-align: left;
    font-weight: 600;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 9pt;
  }
}
/* override styles when printing */
@media print { 
  .dataTables_length {
    display: none;
  }
  header, footer, aside, nav, form, iframe, .menu, .hero, .adslot {
    display: none!important; 
  }
  td {
    border: 4px solid #fff;
    /* padding: 17px 18px 17px 21px; */
    font-size: 14px;
    color: #e15c5c;
    /* width: 7%; */
  }
  table td, table th {
    border: 1px solid black!important;
  }
  body {
    font-family: serif;
  }
  @page { 
    size: portrait; 
    webkit-print-color-adjust: exact!important;
    filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);
    box-shadow:  inset 0 0 0 1000px #000;
  } 
  tr {
    background-color: #ff0707!important;
  }
  table {
    width: 100%;
    border: 1px solid black;
  }
  tbody {
    border: 1px solid black;
  }
}
</style>
