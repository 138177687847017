<template>
<div>
  <div id="content">
      <!-- <template v-if="$route.path === '/our-sports/' + this.slug + '/hall-of-fame' || $route.path === '/coaching-and-teaching/teaching/teacher-stories' || $route.path === '/coaching-and-teaching/coaching/coaching-stories' || $route.path === '/volunteers/volunteer-stories'">
        <Splash
          :bannerImage="page[0].acf.banner_image"
          :video="page[0].acf.video"
        />
      </template> -->
      <template v-if="$route.params.slug === 'masters'">
        <Splash
          :bannerImage="page[2].acf.banner_image"
        />
      </template>
      <template v-else-if="$route.params.slug === 'para-swimming'">
        <Splash
          :bannerImage="page[1].acf.banner_image"
        />
      </template>
      <template v-else>
        <Splash
          :bannerImage="page[0].acf.banner_image"
        />
      </template>
      <div class="ourContainer" :class="isMobile(true)">
        <!-- <Route /> -->
        <!-- <template v-if="$route.path === '/about-us/governance/hall-of-fame' || $route.path === '/our-sports/' + this.slug + '/hall-of-fame'">
        </template> -->
        <template v-if="$route.path.includes('contact-us') || $route.path.includes('about-us') || $route.path.includes('our-sports') || $route.path.includes('events') || $route.path.includes('coaching-and-teaching') || $route.path.includes('volunteers') || $route.path.includes('wellbeing-and-protection') || $route.path.includes('clubs')">

        </template>
        <template v-else>
          <h1 class="mainPagesHead" v-html="this.page[0].title.rendered"></h1>
        </template>

        <!-- <template v-if="$route.path.includes('sitemap')"> -->
        <!-- </template> -->
        
        <!-- <template v-if="$route.path === '/coaching-and-teaching/teaching/teacher-stories' || $route.path === '/coaching-and-teaching/coaching/coaching-stories' || $route.path === '/volunteers/volunteer-stories'">
          <h1 class="mainPagesHead">{{this.page[0].title.rendered}}</h1>
          <p class="SportsSection" v-html="this.page[0].acf.story_blog"></p>
        </template> -->
        <template v-if="page[0].slug !== 'records' && page[0].slug !== 'volunteer-stories'">
          <template v-if="$route.path === '/clubs'">
            <template v-if="page[0].acf.show_content_above_tiles == true">
              <span class="wpContent" v-html="page[0].content.rendered"></span>
              <!-- <span class="hidedesk" v-html="page[0].content.rendered"></span> -->
              <!-- <div class="hidedesk" v-if="page[0].content.rendered.length > 0">
                <span v-bind:class="[isActive ? 'noexpand' : 'expand']" class="wpContent" v-html="page[0].content.rendered.substring(0,2000)"></span>
                <div>
                  <b-button @click="Colorcss()" class="readMoreBtn" v-b-toggle.collapse-1 variant="primary">Read More</b-button>
                  <b-collapse id="collapse-1" class="mt-2 wpcontentHeight readMoreContent">
                    <span class="wpContent" v-html="page[0].content.rendered.substring(2000,100000)"></span>
                  </b-collapse>
                  <b-button @click="Colorcss()" class="readLessBtn" v-b-toggle.collapse-1 variant="primary">Read Less</b-button>
                </div>
              </div> -->
            </template>
            <template v-if="page[0].acf.show_content_above_tiles == true">
              <template v-if="page[0].acf.dropdown_area_show === true">
                <div style="margin-top:30px ">
                  <div>
                    <b-card
                      no-body
                      class="mb-1"
                      v-for="(drop, index) in page[0].acf.dropdown_area"
                      :key="index"
                    >
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle="'one' + index"
                          :id="index"
                          class="faqQ"
                          >
                          <span class="recordsTitle">
                            {{ drop.section_title }}
                          </span>
                          <span class="arrow" >
                            <span class="plusIcon">
                              <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                            </span>
                          </span>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        :id="'one' + index"
                        :accordion="'one' + index"
                        role="tabpanel"
                      >
                        <b-card-body class="profileCard">
                          <b-card-text v-html="drop.section_content"></b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </template>    
            </template>
          </template>
          <template v-if="$route.path == '/clubs/manage-your-club' || $route.path == '/clubs/develop-your-club'">
          </template>
          <template v-else>
            <div :class="{mediaShownone : $route.path == '/media-browser-files'}">
              <h2 class="pageTitle" v-if="page[0].acf.page_intro_title !== undefined && page[0].acf.page_intro_title !== ''">{{page[0].acf.page_intro_title}}</h2>
              <p class="pagedescript" v-if="page[0].acf.page_intro_description !== undefined && page[0].acf.page_intro_description !== ''"  v-html="page[0].acf.page_intro_description"></p>
            </div>
            <Tileshow />
            <div v-if="page[0].acf.bottomblock_paragraph !== undefined && page[0].acf.bottomblock_paragraph !== ''"  v-html="page[0].acf.bottomblock_paragraph" class="sitemap"></div>
          </template>
        </template>
        <div class="wp" :class="{'wpmargin' : $route.name == 'faq'}">
          <b-row>
            <b-col
              cols="12"
              :class="{ 'col-lg-9 col-md-9': page[0].acf.right_sidebar === true }"
            >
              <!-- This is Wordpress rendered content for the page -->
              <!-- <template v-if="$route.path.includes('important-documents')">
                <b-row>
                  <b-col v-for="(importantDocs, index) in page[0].acf.text_box" :key="index" cols="12" lg="4" md="4">
                    <b-nav-item :to='importantDocs.url' class="backgroundNavItem">
                      <div class="outerImportantDocsText">
                        <div class="importantDocsText">
                          {{importantDocs.text}}
                        </div>
                        <font-awesome-icon class="icon" :icon="['fa', 'arrow-right']"/>
                      </div>
                    </b-nav-item>
                  </b-col>
                </b-row>
              </template> -->
              <!-- Commonwealth Games Block -->
                <template v-if="$route.name === 'commonwealth-games'">
                  <b-row>
                    <b-col class="commonwealthImages" v-for="(commonwealth, index) in page[0].acf.sectionblock" :key='index' lg="4" md="4" cols="12">
                      <b-link :to="commonwealth.url">
                        <img :src="commonwealth.image">
                      </b-link>
                    </b-col>
                  </b-row>
                </template>
              <!-- End of Commonwealth Games Block -->
              <template v-if="$route.name === 'teacher-stories' || $route.name === 'coaching-stories' || $route.path === '/about-us/hall-of-fame' || $route.path === '/coaching-and-teaching/coaching/coaching-stories' || $route.path === '/coaching-and-teaching/teaching/teaching-stories'">
                <span class="wpContent" v-html="page[0].content.rendered"></span>
              </template>
              <template
                v-else-if="
                  $route.path ===
                    '/coaching-and-teaching/learning-and-development/'
                 || $route.name === 'learning-and-development'"
              >
                <span class="wpContent" v-html="page[0].content.rendered"></span>
              </template>
              <template v-else-if="$route.path.includes('records') || $route.path === '/coaching-and-teaching/teaching/teacher-stories' || $route.path === '/coaching-and-teaching/coaching/coaching-stories'">
              </template>
              <template v-else-if="$route.path.includes('our-sports') || $route.path === '/about-us/governance/board-and-council-minutes/' ">
                <span class="wpContent" v-html="page[0].content.rendered"></span>
              </template>
              <template v-else>
                <template v-if="$route.path === '/clubs'">
                  <template v-if="page[0].acf.show_content_above_tiles == false">
                    <span class="wpContent" v-html="page[0].content.rendered"></span>
                    <!-- <span class="hidedesk" v-html="page[0].content.rendered"></span> -->
                    <!-- <div class="hidedesk" v-if="page[0].content.rendered.length > 0">
                      <span v-bind:class="[isActive ? 'noexpand' : 'expand']" class="wpContent" v-html="page[0].content.rendered.substring(0,2000)"></span>
                      <div>
                        <b-button @click="Colorcss()" class="readMoreBtn" v-b-toggle.collapse-1 variant="primary">Read More</b-button>
                        <b-collapse id="collapse-1" class="mt-2 wpcontentHeight readMoreContent">
                          <span class="wpContent" v-html="page[0].content.rendered.substring(2000,100000)"></span>
                        </b-collapse>
                        <b-button @click="Colorcss()" class="readLessBtn" v-b-toggle.collapse-1 variant="primary">Read Less</b-button>
                      </div>
                    </div> -->
                  </template>
                </template>
                <template v-else>
                <span class="wpContent" v-html="page[0].content.rendered"></span>
                <!-- <span class="hidedesk" v-html="page[0].content.rendered"></span> -->
                  <!-- <div class="hidedesk" v-if="page[0].content.rendered.length > 0">
                    <span v-bind:class="[isActive ? 'noexpand' : 'expand']" class="wpContent" v-html="page[0].content.rendered.substring(0,2000)"></span>
                    <div>
                      <b-button @click="Colorcss()" class="readMoreBtn" v-b-toggle.collapse-1 variant="primary">Read More</b-button>
                      <b-collapse id="collapse-1" class="mt-2 wpcontentHeight readMoreContent">
                        <span class="wpContent" v-html="page[0].content.rendered.substring(2000,100000)"></span>
                      </b-collapse>
                      <b-button @click="Colorcss()" class="readLessBtn" v-b-toggle.collapse-1 variant="primary">Read Less</b-button>
                    </div>
                  </div> -->
                </template>
              </template>
              <template v-if="$route.name == 'inspired-to-try'">
                 <Disciplines />
              </template>
              <!-- End is Wordpress rendered content for the page -->

            <!-- AQUATIC EVENTS -->
              <template v-if="$route.path === '/events/events-calendar/aquatic-events'">
                <iframe
                  class="iframeBoarder"
                  style="border: none!important;"
                  src="https://www.calendarwiz.com/calendars/calendar.php?crd=aquaticevents&cid[]=all&jsenabled=1&winh=1177&winw=1437&inifr=false"
                >
                </iframe>
                <script type="application/javascript" src="https://events.timely.fun/embed.js" data-src="https://events.timely.fun/jk035ebw/month" data-max-height="0"  id="timely_script" class="timely-script"></script>
                <!-- <Aquaticevents /> -->
              </template>
            <!-- End of AQUATIC EVENTS -->
            <!-- LEARNING AND DEVELOPMENT -->
              <template v-if="$route.path === '/events/events-calendar/learning-and-development'">
                <iframe
                  class="iframeBoarder"
                  style="border: none!important;"
                  src="https://www.calendarwiz.com/calendars/calendar.php?crd=scottishswimming&cid[]=all"
                >
                </iframe>
              </template>
            <!-- LEARNING AND DEVELOPMENT -->
            <!-- NETWORKING EVENTS -->
              <template v-if="$route.path === '/events/events-calendar/networking-events'">
                <iframe
                  class="iframeBoarder"
                  style="border: none!important;"
                  src="https://www.calendarwiz.com/calendars/calendar.php?crd=networkingevents&cid[]=all"
                >
                </iframe>
              </template>
            <!-- End of NETWORKING EVENTS -->
            <!-- QUALIFICATIONS -->
              <template v-if="$route.path === '/events/events-calendar/qualifications'">
                <iframe
                  class="iframeBoarder"
                  style="border: none!important;"
                  src="https://www.calendarwiz.com/calendars/calendar.php?crd=qualifications&cid[]=all"
                >
                </iframe>
              </template>
            <!-- End of QUALIFICATIONS -->

              <!-- Stories -->
                <template v-if="$route.name === 'teacher-stories' || $route.name === 'coaching-stories' || $route.path === '/coaching-and-teaching/teaching/teacher-stories' || $route.path === '/coaching-and-teaching/coaching/coaching-stories' || $route.path === '/volunteers/volunteer-stories'">
                  <div class="storyMargin">
                    <b-row>
                      <b-col v-for="(story, index) in page[0].acf.stories" :key="index" lg="4" md="4" cols="12">
                        <div v-if="story.story_url.length > 0" class="story" v-html="story.story_url"></div>
                        <div v-else class="story">
                          <b-link :href="story.news_story" target="_blank">
                            <img :src="story.story_image.sizes.medium_large">
                          </b-link>
                        </div>
                        <div data-v-dc55c0ba="" class="storyouterName">
                          <b-link :href="story.news_story" target="_blank">
                          <span data-v-dc55c0ba="" class="storyName"> {{story.story_name}}</span>
                          </b-link>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </template>
              <!-- End of Stories -->

              <!-- Olympic Team Members -->
                <template v-if="$route.path === '/events/international-events/olympics'">
                  <div class="storyMargin">
                    <b-row>
                      <b-col v-for="(story, index) in page[0].acf.team_members" :key="index" lg="4" md="4" cols="12">
                        <div class="story">
                          <b-link :to="story.member_url" target="_blank">
                            <img :src="story.image.sizes.medium_large">
                          </b-link>
                        </div>
                        <div data-v-dc55c0ba="" class="storyouterName">
                          <b-link :to="story.member_url" target="_blank">
                          <span data-v-dc55c0ba="" class="storyName"> {{story.member_name}}</span>
                          </b-link>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </template>
              <!-- End of Olympic Team Member -->

              <!-- Form -->
              <template v-if="page[0].acf.form_activate === true">
                <Form :page="page[0]" />
              </template>
              <!-- End of Form -->


              <!-- PDFs -->
              <template v-if="$route.path == '/media-browser-files'">
                <div class="accordion" role="tablist" v-for="(faq, index) in page[0].acf.pdf_downloads" :key="index">
                  <template v-if="faq.sub_header !== ''">
                    <p class="PdfTitle">{{faq.sub_header}}</p>
                  </template>
                  <b-card
                    no-body
                    class="mb-1"
                    :key="index"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle="'faq' + index"
                        :id="index"
                        class="faqQ"
                        >
                        {{ faq.category }}
                          <span class="arrow" >
                            <span class="plusIcon">
                              <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                            </span>
                          </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'faq' + index"
                      :accordion="'faq' + index"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text v-html="faq.download_links"></b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </template>
              <!-- End PDFs -->


              <!-- FAQs -->
              <template v-if="page[0].acf.faq === true">
                <div class="accordion" role="tablist">
                  <b-card
                    no-body
                    class="mb-1"
                    v-for="(faq, index) in page[0].acf.faq_list"
                    :key="index"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle="'faq' + index"
                        :id="index"
                        class="faqQ"
                        >
                        {{ faq.question }}
                          <span class="arrow" >
                            <span class="plusIcon">
                              <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                            </span>
                          </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'faq' + index"
                      :accordion="'faq' + index"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>{{ faq.answer }}</b-card-text>
                        <b-card-text>{{ text }}</b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </template>
              <!-- End FAQs -->

              <!-- dropdown sec -->
              <template v-if="$route.path === '/clubs'">
                <template v-if="page[0].acf.show_content_above_tiles == false">
                  <template v-if="page[0].acf.dropdown_area_show === true">
                    <div style="margin-top:30px ">
                      <div>
                        <b-card
                          no-body
                          class="mb-1"
                          v-for="(drop, index) in page[0].acf.dropdown_area"
                          :key="index"
                        >
                          <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button
                              block
                              v-b-toggle="'one' + index"
                              :id="index"
                              class="faqQ"
                              >
                              <span class="recordsTitle">
                                {{ drop.section_title }}
                              </span>
                              <span class="arrow" >
                                <span class="plusIcon">
                                  <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                                </span>
                              </span>
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            :id="'one' + index"
                            :accordion="'one' + index"
                            role="tabpanel"
                          >
                            <b-card-body class="profileCard">
                              <b-card-text  v-html="drop.section_content"></b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                    </div>
                  </template>    
                </template>
              </template> 
              <template v-else>
                <template v-if="page[0].acf.dropdown_area_show === true">
                  <div style="margin-top:30px ">
                    <div>
                      <b-card
                        no-body
                        class="mb-1"
                        v-for="(drop, index) in page[0].acf.dropdown_area"
                        :key="index"
                      >
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button
                            block
                            v-b-toggle="'one' + index"
                            :id="index"
                            class="faqQ"
                            >
                            <span class="recordsTitle">
                              {{ drop.section_title }}
                            </span>
                            <span class="arrow" >
                              <span class="plusIcon">
                                <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                              </span>
                            </span>
                          </b-button>
                        </b-card-header>
                        <b-collapse
                          :id="'one' + index"
                          :accordion="'one' + index"
                          role="tabpanel"
                        >
                          <b-card-body class="profileCard">
                            <b-card-text  v-html="drop.section_content"></b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </template>
              </template>
              <!-- End of dropdown -->


              <!-- Tables -->
              <!-- <template v-if="page[0].acf.table === true">
                <div class="accordion" role="tablist">
                  <b-card
                    no-body
                    class="mb-1"
                    v-for="(table, index) in page[0].acf.table_list"
                    :key="index"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle="'table' + index"
                        :id="index"
                        class="faqQ"
                        >{{ table.title }}<span class="arrow"></span
                      ></b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'table' + index"
                      :accordion="'table' + index"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text v-html="table.text"></b-card-text>
                        <b-card-text>{{ text }}</b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </template> -->
              <!-- End Tables -->

              <!-- Find A Club Banner -->
              <!-- <template v-if="page[0].acf.club_finder_banner === true">
                <ClubFinder :page="page[0]" />
              </template> -->
              <!-- End Find A Club Banner -->

              <!-- Records -->
              <template v-if="$route.name === 'records'">
                <Records />
              </template>
              <!-- End Records -->

              <!-- Hall of Fame -->
              <template v-if="$route.name === 'hall-of-fame'">
                <HallOfFame />
              </template>
              <!-- End Hall of Fame -->

              <!-- Health Banner -->
              <template v-if="page[0].acf.health_section === true">
                <Benefits :page="page[0]" />
              </template>
              <!-- End Health Banner -->

              <!-- Important Documents -->
              <template
                v-if="
                  $route.name === 'swimming-important-documents' ||
                    $route.name === 'para-swimming-important-documents' ||
                    $route.name === 'masters-important-documents' ||
                    $route.name === 'open-water-important-documents' ||
                    $route.name === 'diving-important-documents' ||
                    $route.name === 'artistic-swimming-important-documents' ||
                    $route.name === 'water-polo-important-documents'
                "
              >
                <ImportantDocuments />
              </template>
              <!-- End Important Documents -->

              <!-- Meet the team -->
              <template v-if="$route.name === 'meet-the-team'">
                <Meettheteam />
              </template>
              <!-- End Meet the team -->

              <!-- Find A Club -->
              <template v-if="$route.name === 'find-a-club'">
                <b-embed
                  type="embed"
                  aspect="1by1"
                  src="https://live-scotswim-full.ocs-software.com/wp-content/themes/twentytwentyone/club_finder/ClubTest.html"
                ></b-embed>
              </template>
              <!-- End Find A Club -->

              <!-- Dynamic Forms -->
              <template v-if="$route.name === 'dynamic-forms'">
                <b-embed
                  type="embed"
                  aspect="1by1"
                  src="https://live-scotswim-full.ocs-software.com/dynamic-forms/?form_id=11"
                ></b-embed>
              </template>
              <!-- End Dynamic Forms -->

              <!-- Job Forms -->
              <template v-if="$route.name === 'job-forms'">
                <b-embed
                  type="embed"
                  aspect="1by1"
                  src="https://live-scotswim-full.ocs-software.com/job-forms/?form_id=17"
                ></b-embed>
              </template>
              <!-- End Job Forms -->

              <!-- Membership Check-->
              <template v-if="$route.name === 'membership-check'">
                <b-embed
                  type="embed"
                  aspect="1by1"
                  src="https://live-scotswim-full.ocs-software.com/wp-content/themes/twentytwentyone/club_finder/SS-Member-Findeer.html"
                ></b-embed>
              </template>
              <!-- End Membership Check -->

              <!-- Event Info for Compete Pages -->
              <template v-if="$route.name === 'compete'">
                <div class="bottom">
                  <UpComing discipline="major-events" total="all" />
                </div>
              </template>
              <!-- End Event Info for Compete Pages -->
            </b-col>

            <!-- Right Side Bar -->
            <template v-if="page[0].acf.right_sidebar === true">
              <b-col>
                <!-- Ads -->
                <template v-if="page[0].acf.ad_links.length">
                  <SideBarBlock
                    v-for="(link, index) in page[0].acf.ad_links"
                    :key="index"
                    type="ad"
                    :image="link.image"
                    :url="link.url"
                  />
                </template>
                <!-- End Ads -->

                <!-- Downloads -->
                <template v-if="$route.path === '/our-sports/swimming/records'">
                  <template v-if="page[0].acf.downloads.length">
                    <div class="downloadsLink">
                      <h4 class="sideTitle">
                        <template v-if="page[0].acf.downloads_header_text == true ">
                          <h4 class="sideTitle downTop">
                            {{page[0].acf.downloads_header_text}}
                            <hr class="hrLine">
                          </h4>
                        </template>
                        <template v-else>
                          <h4 class="sideTitle downTop">
                            Downloads
                            <hr class="hrLine">
                          </h4>
                        </template>
                      </h4>
                      <SideBarBlock
                        v-for="(link, index) in page[0].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.params.slug === 'para-swimming'">
                  <template v-if="page[1].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[1].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[1].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[1].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.params.slug === 'masters'">
                  <template v-if="page[2].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[2].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[1].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[2].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="page[0].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[0].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[0].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop"> Downloads 
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[0].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>                  

                <!--End Downloads -->

                <!-- URL Links -->
                <template v-if="$route.path === '/our-sports/swimming/records'">
                  <template v-if="page[0].acf.useful_links.length">
                    <div class="usefulLinks">
                      <template v-if="page[0].acf.usefull_links_text == true">
                        <h4 class="sideTitle top">
                          {{page[0].acf.usefull_links_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle top">
                          Useful Links
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[0].acf.useful_links"
                        :key="index"
                        type="link"
                        :title="link.title"
                        :url="link.url"
                      />
                    </div>
                  </template>  
                </template>   
                <template v-else-if="$route.params.slug === 'para-swimming'">
                  <template v-if="page[1].acf.useful_links.length">
                    <div class="usefulLinks">
                      <template v-if="page[1].acf.usefull_links_text == true">
                        <h4 class="sideTitle top">
                          {{page[1].acf.usefull_links_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle top">
                          Useful Links
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[1].acf.useful_links"
                        :key="index"
                        type="link"
                        :title="link.title"
                        :url="link.url"
                      />
                    </div>
                  </template>  
                </template>  
                <template v-else-if="$route.params.slug === 'masters'">
                  <template v-if="page[2].acf.useful_links.length">
                    <div class="usefulLinks">
                      <template v-if="page[2].acf.usefull_links_text == true">
                        <h4 class="sideTitle top">
                          {{page[2].acf.usefull_links_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle top">
                          Useful Links
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[2].acf.useful_links"
                        :key="index"
                        type="link"
                        :title="link.title"
                        :url="link.url"
                      />
                    </div>
                  </template>  
                </template>            
                <template v-else>
                  <template v-if="page[0].acf.useful_links.length">
                    <div class="usefulLinks">
                      <template v-if="page[0].acf.usefull_links_text == true">
                        <h4 class="sideTitle top">
                          {{page[0].acf.usefull_links_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle top">
                          Useful Links
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[0].acf.useful_links"
                        :key="index"
                        type="link"
                        :title="link.title"
                        :url="link.url"
                      />
                    </div>
                  </template>  
                </template> 
                <!-- End URL Links -->
              </b-col>
            </template>
            <!-- End Right Side Bar -->
          </b-row>
        </div>
      </div>
    <!-- Sign Up Banner -->
    <!-- <template v-if="page[0].acf.news_letter_sign_up === true">
      <div class="top">
        <SignUp />
      </div>
    </template> -->
    <!--End Sign Up Banner -->
  </div>  
    <Footer />
</div>

</template>

<script>
import apiCall from "@/services/api.js";
import Footer from "@/components/footer.vue"
import SideBarBlock from "@/components/sidebarBlock.vue";
import HallOfFame from "@/components/hallOfFame.vue";
import Records from "@/components/records.vue";
import Meettheteam from "@/components/meettheteam.vue";
import ImportantDocuments from "@/components/importantDocuments.vue";
// import Route from "@/components/route.vue";
import Splash from "@/components/splash.vue";
// import SignUp from "@/components/signUp.vue";
import UpComing from "@/components/upComing.vue";
// import ClubFinder from "@/components/clubFind.vue";
import Benefits from "@/components/benefits.vue";
import Form from "@/components/Form.vue";
import Tileshow from "@/components/tileshow.vue";
import Disciplines from "@/components/disciplines.vue"
// import Aquaticevents from "@/components/aquaticEvents.vue";

export default {
  name: "mainPages",
  props: ["slug"],
  components: {
    // Aquaticevents,
    Footer,
    Form,
    Benefits,
    SideBarBlock,
    HallOfFame,
    // Route,
    Splash,
    // SignUp,
    UpComing,
    Meettheteam,
    ImportantDocuments,
    // ClubFinder,
    Records,
    Tileshow,
    Disciplines
  },
  data() {
    return {
      page: [],
      isActive: true,
      title: '',
      description: ''
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    Colorcss: function(){
      this.isActive = !this.isActive;
    },
    isMobile() {
      if( screen.width <= 760 ) {
          this.container = 'container'
          return true;
      }
      else {
          this.container = 'nocontainer'
          return false;
      }
    }
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(
        "wp/v2/pages?slug=" + this.itemLink(this.$route.name) + '&randomadd=' +
          new Date().getTime()
      );
      this.page = first_call.data;
      this.title = first_call.data[0].title.rendered;
      this.description = first_call.data[0].yoast_head_json.og_description;
      console.log("this.description")
      console.log(this.description)
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
.sitemap {
    padding: 15px;
}
::v-deep li.nav-item.hof{
  list-style: none;
  color: #201546;
}
.wp.wpmargin {
  padding-top: 50px!important;
}
.mediaShownone {
  display: none;
}
::v-deep.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  height: 1400px;
}
p.pagedescript {
  padding-left: 15px;
  color: #1a1041;
  font-size: 16px;
  padding-bottom: 10px;
}
.pageTitle {
  padding-bottom: 40px!important;
  font-family: GT-Haptik-Medium;
  color: #1f1545;
  font-size: 2.5rem;
  padding-left: 15px;
  padding: 0;
}
::v-deep li {
  list-style: revert;
  color: #201546;
}
::v-deep li > a {
  list-style: revert;
  color: #201546;
}
::v-deep li > a:hover {
  list-style: revert;
  color: #009fe3!important;
}
::v-deep span.s2 {
  color: #201546;
}
::v-deep li.p2 > a{
  color: #201546;
}
::v-deep li.li2 >a {
  color: #201546;
}
::v-deep li.li2 {
  list-style: revert;
}
::v-deep p.p1 > a {
  color: #201546;
}
::v-deep li.li3 > a{
  color: #201546;
}
::v-deep li.li3 {
  list-style: revert;
}
::v-deep li.p2 {
  list-style: revert;
}
::v-deep .sitemap > table > tbody > tr {
  background-color: transparent;
}
::v-deep .sitemap > table > tbody > tr > td > ul >li{
  list-style: revert;
  color: #1b113e;
}
::v-deep .sitemap > table > tbody > tr > td > ul >li > a{
  color: #201546
}
::v-deep th.essential {
  padding: 0px 0px 0px 0px!important;
}
.commonwealthImages {
  padding-bottom: 25px;
}
li.nav-item.linkText {
  line-height: 18px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h4.sideTitle.downTop {
  /* margin-top: -10px; */
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.usefulLinks {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 10px;
  border-radius: 5px;
  padding-right: 10px;
}
.storyMargin {
  margin-top: 60px;
}
p.SportsSection {
  color: #1f1545;
}
::v-deep .story > iframe {
  height: 260px!important;
}
.storyouterName {
  height: 66px;
  border-radius: 0px 33px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  margin-top: -66px;
  position: absolute;
  padding: 20px;
  text-transform: capitalize;
  padding-right: 75px;
  transition: padding-right 0.5s;
  background-color: #fff;
  cursor: pointer;
}
::v-deep span.storyName {
  color: #000;
}
.storyouterName:hover {
  padding-right: 140px;
}
.storyouterName:hover:after {
  opacity: 1;
  right: 40px;
  position: absolute;
}
.storyouterName:after {
  content: '»';
  opacity: 0;
}
.accordion > .card > .card-header {
  padding: 0px!important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  padding: 0px!important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.card.mb-1 {
  /* padding: 0px; */
  border: 0;
  margin-bottom: 10px!important;
}
.outerImportantDocsText {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100px;
}
.importantDocsText {
  width: 150px;
  text-align: center;
  margin: auto;
}
li.nav-item.backgroundNavItem {
  background: #eff8fc;
  margin-bottom: 50px;
}
li.nav-item.backgroundNavItem:hover {
  background: #a8d6f0;
}
li.nav-item.backgroundNavItem > a{
  color: #000;
  font-weight: 600;
  text-align: center;
}
h1.mainPagesHead {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
  margin-left: 10px;
}
.Tiletitle {
  height: 66px;
  border-radius: 0 33px 33px 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
  padding-right: 75px;
  transition: padding-right .5s;
  background-color: #1f1545;
}
.disCol > .nav-item > .nav-link {
  padding: 0;
}
.disCol {
  padding: 0 5px 50px 5px;
}
::v-deep .hidedesk > span.noexpand > p:last-child {
  opacity: .3;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
::v-deep .hidedesk > span.expand > p:last-child {
  opacity: 1;
}
.readMoreContent > .wpContent {
  color: #201546;
}
button.btn.readMoreBtn.btn-primary.not-collapsed {
  display: none;
}
button.btn.readLessBtn.btn-primary.collapsed {
  display: none;
}
.wpcontentHeight {
  /* margin-top: 30px!important; */
  display: block;
}
.readMoreBtn {
  background-color: #201646!important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: white;
}
.readLessBtn {
  background-color: #201646!important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: white;
}
::v-dee .html5-endscreen.ytp-player-content.videowall-endscreen.ytp-endscreen-paginate.ytp-show-tiles {
  display: none!important;
}
::v-deep iframe {
  width: 100%;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #1f1545;
  background-color: #eff8fc;
  border-color: #545b62;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
button.print:hover {
  background-color: #009fe3;
  color: #fff;
  border: #201546;
  padding: 10px;
  border-radius: 2px;
  margin-left: 8px;
}
button.print {
  background-color: #201546;
  color: #fff;
  border: #201546;
  padding: 10px;
  border-radius: 2px;
  margin-left: 8px;
  display: none;
}
iframe.iframeBoarder {
  width: 100%;
  height: 1200px;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  /* padding-top: 50px; */
  padding-top: 25px;
}
.wp > .row {
  margin-left: 0px;
  margin-right: 0px;
}
h2 {
  padding: 50px 0 0 0;
  color: #201546;
  margin-bottom: -10px;
}
h4 {
  padding: 50px 0 0 0;
  color: #201546;
  margin-bottom: -10px;
}
::v-deep .wpContent > h1 > strong{
  font-weight: 500;
}
::v-deep .wpContent > h1 {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
::v-deep p.PdfTitle {
  /* padding: 30px 0; */
  padding: 21px 0;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Lessfussy.jpg);
  /* background-position: right; */
  background-position: initial;
  padding-left: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: cover;
  background-position: right;
}
::v-deep p.PdfTitle{
  font-weight: 500!important;
}
::v-deep .wpContent > h2 {
  /* padding: 30px 0; */
  padding: 21px 0;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Lessfussy.jpg);
  /* background-position: right; */
  background-position: initial;
  padding-left: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: cover;
  background-position: right;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > p {
  color: #1a1041;
  /* color: #707070; */
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
::v-deep img {
  object-fit: contain;
}
.readMoreBtn.collapsed:after {
  content: ''!important;
  float: none!important;
  padding-right: 0px!important;
}
.readLessBtn.not-collapsed:after {
  content: ''!important;
  float: none!important;
  padding-right: 0px!important;
}
.collapsed:after {
  content: 'Open';
  float: right;
  padding-right: 15px; 
}
.not-collapsed:after {
  content: 'Close';
  float: right;
  padding-right: 15px;
}
.faqQ {
  background-color: transparent;
  border: 0;
  text-align: left;
  color: #1f1545;
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
}
.faqQ:hover {
  background-color: transparent;
  border: 0;
  text-align: left;
  color: #1f1545;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbgHover.jpg);
  border: none!important;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
}
.DropBut {
  background-color: white;
  color: #009fe3;
  font-style: italic;
  border: 0;
  text-align: left;
}
.accordion > .card.drop {
  border-top: 1px solid!important;
  border:none;
  border-radius: 0px;
}
.accordion > .card.drop > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  border: 0px;
  box-shadow: 0px;
  padding: 0px!important;
}
span.arrow {
  float: right;
  display: grid;
  text-align: center;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #1f1545;
  background-color: #eff8fc;
  border-color: #545b62;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
/* .arrow {
  border: solid #009fe3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 15px;
} */

.nav-item > a {
  color: #009fe3;
}
::v-deep .wpContent > ul > li {
  list-style-type: disc;
  color: #201546;
}
.hide0 {
  display: none;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 10px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
.route > li:first-child > a {
  text-decoration: underline;
}
.route > li:nth-child(2) > a {
  text-decoration: underline;
}
::v-deep .wpContent > p > a {
  color: #009fe3;
}
::v-deep table {
  width: 100%;
}
::v-deep thead > tr {
  /* background-color: #009fe3; */
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 0px 16px 0px 20px;
  /* width: 100%; */
  height: 10px!important;
  /* width: 100%; */
}

::v-deep .records-table > tbody > tr > td {
  padding-left: 15px;
}

/* ::v-deep .records-table > thead > tr > td {
  padding-left: 15px;
} */

::v-deep td {
  border: 4px solid #fff;
  /* padding: 17px 18px 17px 21px; */
  font-size: 14px;
  color: #201546;
  /* padding-left: 15px; */
  /* width: 7%; */
}
::v-deep .wpContent > ul > li > a {
  color: #009fe3;
}
::v-deep tr {
  background-color: #f7f7f7;
  height: 0px!important;
}
::v-deep tr > td {
  height: 0px!important;
}
.top {
  /* padding-top: 50px; */
  padding-top: 0px;
  margin-top: 15px;
}
.bottom {
  padding-bottom: 30px;
}
::v-deep a {
  color: #009fe3;
  text-decoration: none;
  background-color: transparent;
}
@media only screen and (min-width: 991px) {
  ::v-deep .hidedesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  ::v-deep .wpContent > h2 {
    padding: 21px 0;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Lessfussy.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center!important;
    padding-left: 30px;
    padding-right: 30px;
  }
  ::v-deep .hidemob {
    display: none;
  }
  ::v-deep img {
    -o-object-fit: contain;
    object-fit: contain;
    height: auto;
  }
  ::v-deep img.wp-image-8616 {
    object-fit: contain!important;
    height: auto;
  }
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px !important;
  }
  ::v-deep iframe {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .wpContent > h1 {
    color: #1f1545;
    padding-bottom: 20px;
    font-family: GT-Haptik-Medium;
    font-size: 2.5rem;
    padding-top: 30px;
  }
  span.recordsTitle {
    width: 200px;
    display: inline-flex;
  }
  span.hidedesk {
    margin-top: 30px;
    display: block;
  }
  .usefulLinks {
    margin-bottom: 30px; 
  }
  ::v-deep .wpContent > figure {
    padding-top: 20px;
    overflow: scroll;
  }
  .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>